<template>
    <div class="payment">
        <div class="order-detail">
            <h2>{{$t('order.orderDetail')}}</h2>
            <div class="order-detail-item">
                <span class="name">{{$t('package.type')}}</span>
                <span class="value">
                    {{order.pricePackage && $t(`package.${order.pricePackage}`)}}
                    <a-tooltip
                        placement="rightTop"
                        arrowPointAtCenter
                        overlayClassName="custom-tooltip"
                        v-if="order.package"
                    >
                        <template slot="title" >
                            <div class="tooltip-item">
                                <span class="name">{{$t('package.openData')}}</span>
                                <span class="content">{{$t('common.all')}}</span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">Rest API</span>
                                <span class="content">
                                    <i :class="order.package.isSupportRest ? 'icon-check' : 'icon-cancel-1'"></i>
                                </span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">{{$t('package.rateLimiting')}}</span>
                                <span class="content">{{$t('package.callsPerMonth', [order.package.request])}}</span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">QPS</span>
                                <span class="content">{{$t('package.qpsShort', {num: order.package.qps})}}</span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">{{$t('package.wsConnections')}}</span>
                                <span class="content">
                                    <i class="icon-cancel-1"></i>
                                </span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">Webhook (Beta)</span>
                                <span class="content">
                                    <i class="icon-cancel-1"></i>
                                </span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">{{$t('package.customerSupport')}}</span>
                                <span class="content">{{$t('package.email')}}</span>
                            </div>
                            <div class="tooltip-item">
                                <span class="name">{{$t('package.price')}}</span>
                                <span class="content">${{order.package.price[0]}}/{{$t('time.month', {num: ''})}}</span>
                            </div>
                        </template>
                        <i class="icon-info"></i>
                    </a-tooltip>
                </span>
            </div>
            <div class="order-detail-item">
                <span class="name">{{$t('order.chooseDuration')}}</span>
                <span class="value">{{order.duration | formatZh}}</span>
            </div>
            <div class="order-detail-item">
                <span class="name">{{$t('package.expireDate')}}</span>
                <span class="value">{{order.expireDate | moment('YYYY-MM-DD HH:mm:ss')}}</span>
            </div>
            <div class="order-detail-item">
                <span class="name">{{$t('order.payable')}}</span>
                <Numberic class="value" :data="order.orderMoney" :fold="false" prefix="$"></Numberic>
            </div>
            <div class="order-detail-item">
                <span class="name">{{$t('order.paymentCoin')}}</span>
                <span class="value">
                    ETH
                    <Numberic class="rate" :data="1 / order.rate" :fold="false">
                        <template slot="prefix">(1ETH=</template>
                        <template slot="suffix">USD)</template>
                    </Numberic>
                </span>

            </div>
            <div class="order-detail-item">
                <span class="name">{{$t('order.costEffective')}}</span>
                <span class="value text-red">{{order.ethMoney}}ETH</span>
            </div>
        </div>
        <div class="payment-qrcode">
            <h2>
                {{$t('order.pay')}}
                <Countdown :date="countdown" v-if="countdown" class="countdown"/>
            </h2>
            <div class="main">
                <div class="left-main">
                    <div id="pay-address"></div>
                    <!-- <img
                        src="https://mifengcha.oss-cn-beijing.aliyuncs.com/static/user/86c629f34c76ac8d3642377be7de252f.png"
                        alt
                    />-->
                    <p>
                        <a-tooltip
                            placement="bottom"
                            arrowPointAtCenter
                            overlayClassName="custom-tooltip"
                        >
                            <template slot="title" >
                                <h2 style="color: #fff;">{{$t('order.paymentInstruction')}}</h2>
                                <ul class="payment-intro">
                                    <li class="f-12">{{$t('order.pi1')}}</li>
                                    <li class="f-12">{{$t('order.pi2')}}</li>
                                    <li class="f-12">{{$t('order.pi3')}}</li>
                                    <li class="f-12">{{$t('order.pi4')}}</li>
                                </ul>
                            </template>
                            <span>{{$t('order.paymentInstruction')}}</span>
                        </a-tooltip>
                        <em class="divider"></em>
                        <a-tooltip
                            placement="bottom"
                            arrowPointAtCenter
                            overlayClassName="custom-tooltip"
                        >
                            <template slot="title" >
                                <h2 style="color: #fff;">{{$t('order.encounterProblems')}}</h2>
                                <i18n tag="p" class="f-12" path="order.paymentWithProblem">
                                    <a class="contact" href="javascript:;" @click="handleContact">{{$t('user.customerService')}}</a>
                                </i18n>
                            </template>
                            <span>{{$t('order.encounterProblems')}}</span>
                        </a-tooltip>
                    </p>
                </div>
                <div class="right-main">
                    <div class="input-item">
                        <div class="item-name">{{$t('order.costEffective')}}</div>
                        <input type="text" disabled v-model="order.ethMoney" />
                    </div>
                    <div class="input-item">
                        <div class="item-name">
                            {{$t('order.paymentAddr')}}
                            <a-tooltip :title="$t('order.copySuccuess')" :visible="copySuccess">
                                <a
                                    href="javascript:;"
                                    class="button button-mini"
                                    v-clipboard:copy="order.address"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                >{{$t('order.copy')}}</a>
                            </a-tooltip>
                        </div>
                        <input type="text" disabled v-model="order.address" />
                    </div>
                    <div class="input-item">
                        <router-link :to="{name: 'account-name', params: {name: 'dashboard'}}" class="button">{{$t('order.paymentFinish')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import QRCode from 'qrcodejs2';
import { fetchOrder } from 'services/orders';
import Countdown from '../components/countdown';
export default {
    name: 'payment',
    components: {
        Countdown
    },
    data () {
        return {
            order: {},
            copySuccess: false,
            countdown: null
        }
    },
    filters: {
        formatZh(value) {
            if(value >= 12) {
                return value / 12 + '年'
            }else {
                return value + '个月'
            }
        },
    },
    computed: {
        ...mapGetters(['packages'])
    },
    mounted () {
        this.getOrder();
    },
    methods: {
        onCopy (e) {
            this.copySuccess = true;
            setTimeout(() => {
                this.copySuccess = false;
            }, 3800);
        },
        onError (e) {
            console.log(e)
        },
        getOrder() {
            fetchOrder({
                id: this.$route.query.order
            })
            .then(res => {
                let data = res.data;
                data.package = null;
                if(data.countdown >= 0) {
                    this.countdown = new Date(Date.now() + (3600000 - data.countdown));
                }
                else {
                    this.countdown = new Date();
                }

                this.order = data;
                let pricePackage = this.packages.find(item => item.name == this.order.pricePackage);
                pricePackage.ws = Number(pricePackage.ws);
                this.order.package = pricePackage
                new QRCode('pay-address', {
                    width: 200,
                    height: 200,
                    text: data.address, // 二维码内容
                    colorLight: 'transparent',
                    correctLevel: QRCode.CorrectLevel.H
                });
            })
            .catch(err => {
                this.$router.push({name: 'login'});
            })
        },
        handleContact() {
            document.querySelector('.chat-btn').click();
        },
    }
}
</script>
<style lang="less" scoped>
@import "../assets/css/theme.less";

.payment {
    width: @container;
    margin: 50px auto;
    display: flex;
}
.order-detail {
    width: 320px;
    padding: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    h2 {
        font-size: 18px;
        margin-bottom: 20px;
    }
}
.order-detail-item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
    .name {
        width: 100px;
        color: #999;
    }
    .icon-info {
        color: #999;
    }
    [class^="icon-"] {
        margin-left: 6px;
        cursor: pointer;
    }
    .rate {
        font-size: 12px;
        color: #999;
    }
}
.custom-tooltip {
    .tooltip-item {
        display: flex;
        align-items: center;
        line-height: 1.5;
        .name {
            width: 120px;
            color: #f1f1f1;
            margin-right: 10px;
            text-align: right;
            &:after {
                content: "：";
            }
        }
    }
}
.payment-qrcode {
    padding: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    flex: 1;
    margin-left: 20px;
    h2 {
        font-size: 18px;
        margin-bottom: 20px;
        span {
            margin-left: auto;
            font-size: 14px;
            font-weight: normal;
        }
    }
}
.main {
    display: flex;
    .left-main {
        width: 200px;
        img {
            width: 200px;
            height: 200px;
        }
        p {
            font-size: 12px;
            color: #169bd5;
            text-align: center;
            margin-top: 10px;
            em {
                display: inline-block;
                height: 1em;
                width: 1px;
                background: #cccc;
                margin: 0 8px;
                vertical-align: top;
            }
        }
    }
    .right-main {
        margin-left: 20px;
        flex: 1;
        .input-item {
            margin-bottom: 20px;
            .item-name {
                margin-bottom: 5px;
                color: #777;
            }
            input {
                width: 100%;
                padding: 8px 10px;
                border-radius: 3px;
                border: 1px solid #f1f1f1;
            }
        }
    }
}
.countdown {
    float: right;
}
.payment-intro {
    counter-reset:sectioncounter;
}
.f-12 {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 5px;
    padding-left: 12px;
    position: relative;
    letter-spacing: 0.1px;
    &::before {
        content: counter(sectioncounter) '.';
        font-size: 12px;
        counter-increment:sectioncounter;
        position: absolute;
        left: 0;
        top: 0;
    }
}
.contact {
    color: @primary-color;
}
</style>
