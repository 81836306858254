<template>
    <div class="countdown">
        <!-- <span class="digit">{{ days | twoDigits }}</span>
        <span class="text">{{$t('time.day', {num: ''})}}</span>
        <span class="digit">{{ hours | twoDigits }}</span>
        <span class="text">{{$t('time.h', {num: ''})}}</span> -->
        <span class="digit">{{ minutes | twoDigits }}</span>
        <span class="text">{{$t('time.m', {num: ''})}}</span>
        <span class="digit">{{ seconds | twoDigits }}</span>
        <span class="text">{{$t('time.second', {num: ''})}}</span>
    </div>
</template>

<script>
let interval = null;
export default {
    name: 'countdown',
    props: {
        date: {
            type: [Date, String]
        }
    },
    data () {
        return {
            now: Math.trunc((new Date()).getTime() / 1000),
        }
    },
    mounted() {
        interval = setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        },1000);
    },
    computed: {
        dateInMilliseconds () {
            return Math.trunc(Date.parse(this.date) / 1000)
        },
        seconds () {
            return (this.dateInMilliseconds - this.now) % 60;
        },
        minutes () {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
        },
        hours () {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
        },
        days () {
            return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
        }
    },
    filters: {
        twoDigits (value) {
            if (value < 0) {
                return '00';
            }
            if (value.toString().length <= 1) {
                return `0${value}`;
            }
            return value;
        }
    },
    destroyed () {
        clearInterval(interval);
    }
}
</script>
<style lang="less" scoped>
@import '~assets/css/theme.less';
.countdown {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 4px;
    .digit {
        color: #666;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
    }
    .text {
        color: #999;
        font-size: 12px;
        margin-left: 2px;
        font-weight: 40;
    }
   
    
}
</style>
